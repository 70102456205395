import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

// dashboard
const PlayersDashboard = React.lazy(() => import('../pages/discover/Players'));
const TeamsDashboard = React.lazy(() => import('../pages/discover/Teams'));
const PlayerDashboard = React.lazy(() => import('../pages/discover/Player'));
const TeamDashboard = React.lazy(() => import('../pages/discover/Team'));
const RankingsDashboard = React.lazy(() => import('../pages/discover/Ranking'));
const TrendingDashboard = React.lazy(() => import('../pages/discover/Trending'));
const Top100Dashboard = React.lazy(() => import('../pages/discover/Top100'));
const TournamentPredictionsDashboard = React.lazy(() => import('../pages/discover/TournamentPredictions'));
const TodaysGamesDashboard = React.lazy(() => import('../pages/discover/TodaysGames'));
const GameRecapDashboard = React.lazy(() => import('../pages/discover/GameRecap'));

// apps
const GamePrediction = React.lazy(() => import('../pages/apps/GamePrediction'));
const NILValuation = React.lazy(() => import('../pages/apps/NILValuation'));
const CareerTrajectory = React.lazy(() => import('../pages/apps/CareerTrajectory'));
const PlayerDevelopment = React.lazy(() => import('../pages/apps/PlayerDevelopment'));
const FutureMatchups = React.lazy(() => import('../pages/apps/FutureMatchups'));
const HowWeWork = React.lazy(() => import('../pages/apps/HowWeWork'));

// pages
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ErrorPageNotReady = React.lazy(() => import('../pages/error/PageNotReady'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Landing = React.lazy(() => import('../pages/landing'));


const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> }
                    ],
                },
                {
                    path: 'error-yikes',
                    element: <LoadComponent component={ErrorPageNotReady} />,
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'upgrade-subscription',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'discover',
                    children: [
                        {
                            path: 'players',
                            element: <LoadComponent component={PlayersDashboard} />,
                        },
                        {
                            path: 'teams',
                            element: <LoadComponent component={TeamsDashboard} />,
                        },
                        {
                            path: 'player',
                            element: <LoadComponent component={PlayerDashboard} />,
                        },
                        {
                            path: 'team',
                            element: <LoadComponent component={TeamDashboard} />,
                        },
                        {
                            path: 'rankings',
                            element: <LoadComponent component={RankingsDashboard} />,
                        },
                        {
                            path: 'top100',
                            element: <LoadComponent component={Top100Dashboard} />,
                        },
                        {
                            path: 'tournament-predictions',
                            element: <LoadComponent component={TournamentPredictionsDashboard} />,
                        },
                        {
                            path: 'headlines',
                            element: <LoadComponent component={TrendingDashboard} />,
                        },
                        {
                            path: 'todays-games',
                            element: <LoadComponent component={TodaysGamesDashboard} />,
                        },
                        {
                            path: 'game-recap',
                            element: <LoadComponent component={GameRecapDashboard} />,
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'game-prediction',
                            element: <LoadComponent component={GamePrediction} />,
                        },
                        {
                            path: 'how-we-work',
                            element: <LoadComponent component={HowWeWork} />,
                        },
                        {
                            path: 'nil-valuation',
                            element: <LoadComponent component={NILValuation} />,
                        },
                        {
                            path: 'player-potential',
                            element: <LoadComponent component={CareerTrajectory} />,
                        },
                        {
                            path: 'player-development',
                            element: <LoadComponent component={PlayerDevelopment} />,
                        },
                        {
                            path: 'smart-scouting-report',
                            element: <LoadComponent component={FutureMatchups} />,
                        },
                    ],
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Premium'} component={Layout} />,
            children: [
                {
                    path: 'discover',
                    children: [
                        {
                            path: 'players',
                            element: <LoadComponent component={PlayersDashboard} />,
                        },
                        {
                            path: 'teams',
                            element: <LoadComponent component={TeamsDashboard} />,
                        },
                        {
                            path: 'player',
                            element: <LoadComponent component={PlayerDashboard} />,
                        },
                        {
                            path: 'team',
                            element: <LoadComponent component={TeamDashboard} />,
                        },
                        {
                            path: 'rankings',
                            element: <LoadComponent component={RankingsDashboard} />,
                        },
                        {
                            path: 'top100',
                            element: <LoadComponent component={Top100Dashboard} />,
                        },
                        {
                            path: 'tournament-predictions',
                            element: <LoadComponent component={TournamentPredictionsDashboard} />,
                        },
                        {
                            path: 'headlines',
                            element: <LoadComponent component={TrendingDashboard} />,
                        },
                        {
                            path: 'todays-games',
                            element: <LoadComponent component={TodaysGamesDashboard} />,
                        },
                        {
                            path: 'game-recap',
                            element: <LoadComponent component={GameRecapDashboard} />,
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'game-prediction',
                            element: <LoadComponent component={GamePrediction} />,
                        },
                        {
                            path: 'how-we-work',
                            element: <LoadComponent component={HowWeWork} />,
                        },
                        {
                            path: 'nil-valuation',
                            element: <LoadComponent component={NILValuation} />,
                        },
                        {
                            path: 'player-potential',
                            element: <LoadComponent component={CareerTrajectory} />,
                        },
                        {
                            path: 'player-development',
                            element: <LoadComponent component={PlayerDevelopment} />,
                        },
                        {
                            path: 'future-matchups',
                            element: <LoadComponent component={FutureMatchups} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
