import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { API_URL } from '../API';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

export function configureFakeBackend() {
    fetch(API_URL+"get_user").then( async (data)=>{
            return await data.json();
        }).then((users)=>{        
            mock.onPost('/login/').reply(function (config) {
                return new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        // get parameters from post request
                        let params = JSON.parse(config.data);
        
                        // find if any user matches login credentials
                        let filteredUsers = users.filter((user) => {
                            return user.username === params.username && user.password === params.password;
                        });
                        if (filteredUsers.length) {
                            // if login details are valid return user details and fake jwt token
                            let user = filteredUsers[0];
                            resolve([200, user]);
                        } else {
                            // else return error
                            resolve([401, { message: 'Username or password is incorrect' }]);
                        }
                    }, 1000);
                });
            });
        
            mock.onPost('/logout/').reply(function (config) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve([200, 'You have successfully logged out!']);
                    });
                });
            });
        
            mock.onPost('/register/').reply(function (config) {
                return new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        let params = JSON.parse(config.data);
                        let [firstName, lastName] = params.fullname.split(' ');

                        fetch(API_URL+"add_user?username="+params.email+
                                                            "&password="+params.password+
                                                            "&firstName="+firstName+
                                                            "&lastName="+lastName+
                                                            "&teamRole="+params.roleselect).then( async (data)=>{
                            return await data.json();
                        }).then((users)=>{ 
                            
                            let filteredUsers = users.filter((user) => {
                                return user.username === params.email && user.password === params.password;
                            });
                            if (filteredUsers.length) {
                                let user = filteredUsers[0];
                                resolve([200, user]);
                            };
                        });
                        
                    }, 1000);
                });
            });
        
            mock.onPost('/forget-password/').reply(function (config) {
                return new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        // get parameters from post request
                        let params = JSON.parse(config.data);
        
                        // find if any user matches login credentials
                        let filteredUsers = users.filter((user) => {
                            return user.username === params.username;
                        });
        
                        if (filteredUsers.length) {
                            // if login details are valid return user details and fake jwt token
                            let responseJson = {
                                message: "We'll send you an email with your password.",
                            };
                            fetch(API_URL+"forgotten_password?username="+params.username)
                            resolve([200, responseJson]);
                            
                        } else {
                            // else return error
                            resolve([
                                401,
                                {
                                    message: 'Sorry, we could not find any registered user with entered username',
                                },
                            ]);
                        }
                    }, 1000);
                });
            });
    
    
    })
}
