import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

type PrivateRouteProps = {
    component: React.ComponentType,
    roles?: string,
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */

const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const api = new APICore();
    const loggedInUser = api.getLoggedInUser();
    var userAuthorization = document.cookie;
    /**
     * not logged in so redirect to login page with the return url
     */
    
    
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }
    
     //check if route is restricted by role
    if ( (userAuthorization.includes("role=Admin") === false) && (userAuthorization.includes("role=Premium") === false) ) {
        // role not authorised so redirect to home page
        console.log('user not signed in')
        document.cookie = "authorized=true; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return <Navigate to={{ pathname: '/' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
